.icon-selection {
	background: green;
	border-radius: 50%;
	color: white;
  font-size: 40px !important;
	position: absolute !important;
	right: -10px;
	top: -10px !important;
}

.question-modal__content-html {
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	margin-bottom: 20px;
	/* max-height: 350px; */
	/* overflow-y: scroll; */
	padding: 10px;
}

.question-modal__content-html img {
	max-width: 100%;
}

.question-modal__close {
	position: fixed;
	right: 30px;
	top: 30px;
	z-index: 999999999;
}

.question-modal__navigation-controls {
	margin-bottom: 20px;
}

.question-modal__navigation-controls label {
	margin: 0 10px;
}

.ReactModal__Overlay--after-open {
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 99999;
}

.ReactModal__Content {
	box-shadow: 0 0 10px -4px #000;
}

.ReactTable .-pagination .-btn:not(:disabled) {
	text-decoration: underline;
}

.grammar-output__mistake {
	background: #fcff56;
	padding: 5px 10px;
}

.grammar-output__item:not(:last-child) {
	border-bottom: 1px solid #dcdcdc;
	margin-bottom: 10px;
	padding-bottom: 10px;
}

.grammar-output__suggestion-list li {
	margin-bottom: 10px;
}

.grammar-output__suggestion-prompt {
	margin: 10px 0;
}

.grammar-output__replace-button {
	margin-left: 10px;
	padding: 5px !important;
}